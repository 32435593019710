@import '../../../styles/variables/colors.scss';

.block {
  height: 210px;
  padding-bottom: 20px;
}

.container {
  padding-left: 25px;
  height: calc(100% - 62px);
  overflow: scroll;
}

.item {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

.itemDate {
  font-size: 13px;
  color: rgba($color: $romance, $alpha: 0.5);
}

.itemNote {
  font-size: 15px;
  color: $romance;
}
