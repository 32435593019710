@import 'styles/variables/colors';

.root {
  width: 100%;

  &:not(:first-child) {
    margin-top: 16px;
  }

  &Tasting {
    margin-top: 30px;
  }
}

.product {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.productWrapper {
  display: flex;
  align-items: center;
  flex: 1;
}

.productTitle,
.productAmount {
  color: $romance;
  font-size: 18px;
}

.productAmount {
  margin-right: 12px;
}

.productTitleContent {
  &Italic {
    font-style: italic;
  }
}

.productButton {
  width: 16px;
  height: 16px;
  margin-left: 10px;
}

.productInfo {
  display: flex;
  color: $romance;
}

.productPrice {
  width: 60px;
  display: flex;
  justify-content: flex-end;
  font-size: 18px;
}

.productCaption {
  display: block;
  margin-top: 4px;
  color: rgba($romance, 0.5);
  text-transform: uppercase;
  font-size: 16px;
}

.refundedProduct {
  * {
    color: rgba($romance, 0.6);
  }
}

.subProduct {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.subProductTitle {
  width: 220px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 20px;
  line-height: 17px;
  margin-left: 30px;
  color: rgba($romance, 0.7);
}

.subProductPriceWrapper {
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 17px;
  text-align: right;
  color: rgba($romance, 0.8);
}

.subProductPrice {
  margin-right: 0 !important;
}

.smallText {
  margin-left: 8px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
  color: rgba($romance, 0.4);
}
