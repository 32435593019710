@import '../../styles/variables/colors';

.container {
  border-bottom: thin solid $white;
  position: relative;
}

.input {
  padding: 12px 12px 12px 0;
  font-size: 22px;
  color: $white;

  &::placeholder {
    opacity: 0.6;
  }
}

.errorContainer {
  border-bottom: thin solid $red;

  .input {
    color: $red;
  }
}

.error {
  position: absolute;
  bottom: -10px;
  left: 0;
  color: $red;
  font-size: 16px;
  transform: translateY(100%);
}
