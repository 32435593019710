@import 'styles/variables/colors';

$genericPadding: 26px;
$paginationGap: 29px;

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.table {
  width: 100%;
  transition: 0.3s;
}

.loadingTable {
  opacity: 0.5;
}

.bodyCell {
  text-align: left;
  padding: 5px;

  a {
    padding-left: 0;
    font-size: 18px;
  }
}

.grayCell {
  color: rgba($romance, 0.4);
}

.idCell {
  color: rgba($romance, 0.4);
}

.statusCell {
  white-space: pre;
  color: rgba($romance, 0.4);
}

.actionCell {
  padding: 0 15px;
}

.bodyRow {
  border-top: 1px solid rgba(237, 234, 227, 0.1);
  font-size: 20px;
}

.headCell {
  text-align: left;
  padding: $genericPadding 10px 18px 10px;
  color: $romance;
  font-size: 20px;
  cursor: pointer;
}

.btnInfo {
  padding: 6px 13px;
  border: 1px solid $romance;
  border-radius: 100%;
  font-size: 18px;
  color: $romance;
  letter-spacing: -0.08px;
  text-align: center;
  transition: 0.2s;

  &:hover {
    color: black;
    background: white;
    border-color: white;
  }
}

.bodyCellRow {
  display: flex;
  align-items: center;
  flex: 0;
}
