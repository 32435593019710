@import '../../styles/variables/colors';

.container {
  width: 100%;
  min-height: 100vh;
  background-color: $black;
  position: fixed;
  top: 0;
  left: 0;
}
