.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.line {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.dots {
  margin-top: -4px;
}

.icon {
  margin-top: 20px;
}

.step {
  margin-right: 4px;
}
