@use '~styles/variables' as *;

.blockContainer {
  background: $color-gray5;
  border-radius: 20px;
  padding: 26px;

  &Refunds {
    margin-top: 20px;
  }
}

.block {
  display: flex;
  flex-direction: column;
  width: 100%;

  &ProductList {
    margin-bottom: 26px;
  }

  &Total {
    border-top: 1px solid rgba($color-romance, 0.3);
    padding-top: 26px;
  }
}

.orderItemsWrapper {
  padding: 0;
}

.orderItemsTitle {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 18px;
}
