@import 'styles/variables/colors';

.container {
  width: 22px;
  height: 20px;
  border: 1px solid rgba($romance, 0.5);
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
}

.box {
  width: 22px;
  height: 20px;
  background-color: $romance;
  border-radius: 2px;
  transform: translateX(0);
  transition: 0.2s;
}

.input {
  display: none;

  &:checked + .box {
    transform: translateX(50%);
  }
}
