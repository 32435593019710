@import 'styles/variables/colors';

.container {
  cursor: pointer;
  width: 18px;
  height: 20px;
}

.input {
  display: none;
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  background: transparent;
  border: 1px solid rgba($romance, 0.5);
  border-radius: 4px;

  .input:checked ~ & > div {
    visibility: visible;
  }
}

.innerBox {
  width: 20px;
  height: 20px;
  background-color: $romance;
  border-radius: 2px;
  visibility: hidden;
}
