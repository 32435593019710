.root {
  width: 100%;
  max-height: 550px;
  display: flex;
  overflow: scroll;
  flex-wrap: wrap;
}

.category {
  height: 188px;
  width: 150px;
  padding: 10px 11px 16px 11px;
  margin: 0 21px 23px 0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  cursor: pointer;

  &Title {
    font-size: 18px;
  }

  &Count {
    font-size: 16px;
    position: absolute;
    top: 20px;
    right: 18px;
  }
}
