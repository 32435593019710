.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 18px;

  &:first-child {
    margin-top: 0;
  }
}
