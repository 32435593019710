@use '~styles/variables' as *;

.form {
  padding: 24px;
  padding-top: 0;
}

.formGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
}

.formRow {
  display: flex;
  width: 100%;
  border-bottom: thin solid rgba($color-romance, 0.5);
}

.caption {
  padding-right: 12px;
  padding-top: 24px;
}

.input {
  display: flex;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  flex-grow: 1;

  input {
    flex-basis: 90px;
    height: 120px;
    background: transparent;
    color: rgba($color-romance, 0.85);
    font-size: 60px;
    padding: 24px 0;
  }
}

.formSubmit {
  display: flex;
  margin-top: 24px;
  justify-content: flex-end;
}

.submitBtn {
  border-radius: 22px;
  border: thin solid $color-romance;
  padding: 16px 32px;
  font-size: 16px;
  opacity: 0.85;
  letter-spacing: -0.08px;
  transition: 0.2s;
  text-transform: uppercase;

  &:hover {
    color: $color-romance;
  }
}

.error {
  margin-top: 12px;
  color: red;
  font-size: 12px;
}
