@use '~styles/variables' as *;

.root {
  display: flex;
  flex-direction: column;
}

.label {
  font-size: 14px;
  color: $color-romance;
}

.wrapper {
  margin-top: 10px;
}

.input {
  border-bottom: thin solid white;
  padding: 6px 0;
  width: 100%;
  color: white;
  font-size: 28px;

  &::placeholder {
    color: rgba(white, 0.35);
  }
}
