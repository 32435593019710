@import 'styles/variables/colors';

.root {
  margin-top: 45px;
  position: relative;
}

.title {
  font-size: 20px;
}

.price {
  margin-top: 15px;
  margin-bottom: 40px;
  font-size: 45px;
  font-weight: bold;
}

.priceCurrency {
  font-size: 18px;
  font-weight: bold;
  color: rgba($romance, 0.5);
  margin-left: 10px;
  padding-bottom: 6px;
}

.blockTitle {
  font-size: 19px;
  line-height: 22px;
  height: 30px;
}

.cards {
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
}

.paymentButton {
  flex: 1;
  max-width: 120px;
  height: 50px;
  padding: 10px;
  border-radius: 8px;
  font-size: 14px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon {
  margin-top: 5px;
  width: 18px;
  height: auto;
}

.email {
  display: flex;
  flex-direction: column;
  background: $codGray;
  border-radius: 15px;
  margin-top: 20px;
  height: 75px;
  margin-bottom: 44px;
}

.emailInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 20px;
  cursor: pointer;
}

.emailCaption {
  font-size: 14px;
  color: rgba($romance, 0.5);
}

.emailApply {
  font-size: 12px;
}

.cartButtonsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  border-radius: 15px;
  width: 100%;
  background: $codGray;
  padding: 20px;
}

.cartButton {
  font-size: 18px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: $guestNumberButton;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
    border: thin solid rgba($romance, 0.2);
  }
}

.countInput {
  background: $codGray;
  border: none;
  color: white;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  outline: none;
  width: 6rem;
}

.customerInfoWrapper {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  min-height: 12rem;
}

.customerInfo {
  width: 100%;
}

.errorMessage {
  color: red;
  font-size: 16px;
  margin-top: 1rem;
}
