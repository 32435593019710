@use '~styles/variables' as *;

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.bodyRow {
  border-top: 1px solid rgba(237, 234, 227, 0.1);
  height: 50px;
  line-height: 28px;
  font-size: 18px;
}

.bodyCell {
  color: rgba($color-romance, 0.6);
}

.bodyCellRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 28px;
  font-size: 18px;
}

.changedEntry {
  color: $color-romance;
}

.icon {
  scale: 2;
  fill: none;
}
