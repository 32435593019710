@use '~styles/variables' as *;

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: $color-gray5;
  border-radius: 20px;
}

.inner {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  cursor: pointer;
}

.caption {
  font-size: 14px;
  color: rgba($color-romance, 0.5);
}

.apply {
  font-size: 12px;
}
