@import '../../styles/variables/colors';

.container {
  display: flex;
  align-items: flex-start;
  background: $gray5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.0784314);
  border-radius: 20px;
  padding: 28px 28px 30px 28px;
  justify-content: space-between;
}

.text {
  margin-top: 13px;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: rgba($romance, 0.85);
  text-transform: capitalize;
}

.grayText {
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: rgba($romance, 0.5);
}

.total {
  margin-right: 20px;
}
