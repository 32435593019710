@use '~styles/variables' as *;

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  transition: opacity 0.25s;
  padding-top: 6px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 20px;
  display: flex;
  align-items: center;
}

.busy {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}

.tableInfo {
  flex: none;
}

.button {
  border: 1px solid rgba($color-romance, 0.85);
  border-radius: 20px;
  font-size: 14px;
  color: rgba($color-romance, 0.85);
  text-transform: uppercase;
  padding: 5px 18px;
}

.options {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.inner {
  flex: 1 1 auto;
  position: relative;
}

.reservationCard {
  margin-top: 20px;
}

.customers {
  margin-top: 40px;
}

.wineTypes {
  margin-top: 20px;
}

.currentlyTasting {
  margin-top: 21px;
}

.tastingWines {
  margin-top: 22px;
}

.select {
  display: block;
}

.dropdownBtn {
  display: flex;
  align-items: center;
  border-radius: 20px;
  border: thin solid $color-romance;
  padding: 6px 16px;
  padding-right: 12px;
  opacity: 0.75;
  letter-spacing: -0.08px;
  transition: 0.2s;
  font-size: 11px;
  text-transform: uppercase;

  &:hover {
    opacity: 0.9;
    border-color: white;
    color: white;
  }
}
