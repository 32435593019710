@import 'styles/variables/colors';

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: opacity 0.25s;
  padding-top: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 20px;

  p {
    line-height: 22px;
  }
}

.smallText {
  font-size: 15px;
  color: $tundora;
  text-transform: uppercase;
  margin-top: 3px;
}

.busy {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}

.tableInfo {
  flex: none;
}

.inner {
  flex: 1 1 auto;
  position: relative;
}

.reservationCard {
  margin-top: 20px;
}

.customers {
  margin-top: 40px;
}

.wineTypes {
  margin-top: 20px;
}

.currentlyTasting {
  margin-top: 21px;
}

.tastingWines {
  margin-top: 22px;
}

.select {
  display: flex;
  align-items: center;
  justify-items: center;
}

.button {
  border: 1px solid rgba($romance, 0.85);
  border-radius: 20px;
  font-size: 18px;
  color: rgba($romance, 0.85);
  text-transform: uppercase;
  padding: 9px 18px;
  margin: 0;
  white-space: nowrap;
}

.dropdownBtn {
  display: flex;
  align-items: center;
  border-radius: 20px;
  border: thin solid $romance;
  padding: 6px 16px;
  padding-right: 12px;
  opacity: 0.75;
  letter-spacing: -0.08px;
  transition: 0.2s;
  font-size: 11px;
  text-transform: uppercase;

  &:hover {
    opacity: 0.9;
    border-color: white;
    color: white;
  }
}
