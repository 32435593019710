@import '../../styles/variables/colors';

.container {
  position: relative;
}

:global(.input-range__track) {
  background-color: rgba($romance, 0.6) !important;
}

:global(.input-range__track--active) {
  background-color: rgba($gray5, 0.8) !important;
}

:global(.input-range__slider) {
  background-color: $codGray !important;
  border: none !important;
}

:global(.input-range__label-container) {
  display: none;
}
