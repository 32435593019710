@import '../../../../styles/variables/colors';
@import '../../../../styles/variables/fonts';

.printer {
  max-width: 120px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 0.3s;

  &:active {
    opacity: 0.5;
  }
}

.text {
  margin-top: 10px;
  line-height: 25px;
  font-size: 16px;
}

.mac {
  font-size: 14px;
  opacity: 0.5;
}
