.root {
  display: flex;
  align-items: center;
}

.date {
  margin-left: 15px;
  font-size: 18px;
}

.controls {
  display: flex;
  align-items: center;
  margin-left: 18px;
  color: white;

  .control:last-child {
    margin-left: 11px;
  }
}

.calendar {
  &Icon {
    position: relative;
  }

  &Circle {
    position: absolute;
    right: -6px;
    top: -6px;
  }
}
