@import 'styles/variables/colors';

.blockContainer {
  background: $gray5;
  border-radius: 20px;
  padding: 26px;
}

.block {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.blockProductList {
  margin-bottom: 26px;
}

.blockTotal {
  border-top: 1px solid rgba($romance, 0.3);
  padding-top: 26px;
}

.orderItemsWrapper {
  padding: 0;
}

.orderItemsTitle {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 18px;
}

.taxes {
  margin-top: 10px;
  padding-left: 5px;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  max-height: 100px;
  overflow: auto;
}

.fee {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 18px;
}
