@import '../../styles/variables/colors';

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100vw;
}

.codeModal {
  background-color: rgba($black, 0.9) !important;

  h2 {
    font-size: 42px;
    line-height: 46px;
    width: 280px;
    margin-top: 65px;
  }
}

.pendingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $black;
  z-index: 9999;
}

.spinner {
  transform: scale(1.2);
}
