@use '~styles/variables' as *;

.root {
  padding: 329px 450px 116px 152px;
  height: 100%;
  position: relative;
}

.pattern {
  position: absolute;
  right: 0;
  top: -10%;
}

.inner {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;

  &Label {
    font-size: 14px;
    color: rgba($color-romance, 0.5);
    padding-bottom: 2px;
  }

  &Total {
    margin-top: 54px;
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: center;
  }

  &Price {
    font-size: 30px;
    padding: 0 14px;
  }

  &Caption {
    font-size: 22px;
    display: flex;
    justify-content: center;
    color: rgba($color-romance, 0.85);
  }

  &Buttons {
    display: flex;
    justify-content: center;
  }

  &Button {
    font-size: 15px;
    text-transform: uppercase;
    color: rgba($color-romance, 0.5);

    &:first-child {
      margin-right: 45px;
    }
  }
}

.buttonActive {
  color: rgba($color-romance, 0.85);
}
