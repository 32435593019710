@import 'styles/variables/colors';
@import 'styles/variables/dimensions';

.container {
  position: relative;
  min-height: calc(100vh - 100px);
  padding: 28px $dashboardHorizontalPadding;
}

.pendingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $black;
  z-index: 10;
}

.spinner {
  transform: scale(1.2);
}

.header {
  display: flex;
  align-items: center;
}

.footer {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 16px $dashboardHorizontalPadding;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 22px;
  font-weight: 500;
  margin-left: 23rem;
}

.date {
  font-size: 18px;
  font-weight: 500;
  text-align: right;
  color: rgba($romance, 0.5);
  margin-bottom: 1rem;
}

.wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 40px;
}

.tableWrapper {
  position: relative;
  margin-right: 50px;
  height: 400px;
}

.infoWrapper {
  width: 360px;
  overflow-y: scroll;
  flex-shrink: 0;
  margin-top: -3rem;
}

.orderInfo {
  margin-bottom: 15px;

  &AlternativeText {
    font-size: 18px !important;
    line-height: 20px !important;
    text-transform: none !important;
    width: 150px;
  }
}

.comment {
  margin-top: 15px;
}

.totalWrapper {
  display: flex;
  align-items: flex-start;
}

.refundTotal {
  margin-left: 10px;
}

.link {
  font-size: 18px;
  color: rgba($romance, 0.4);
}

.activeLink {
  font-size: 18px;
  color: rgba($romance, 0.85);
}

.underlinedLink {
  font-size: 18px;
  color: $romance;
  margin-left: 30px;
  padding-bottom: 2px;
  border-bottom: thin solid $romance;
}

.linkSeparator {
  margin: 0 8px;
  color: rgba($romance, 0.4);
}

.totalRefundWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  background: $gray5;
  color: $romance;
  border-radius: 5px;
  margin-top: 15px;
  padding: 1rem 1.5rem;
}

.totalRefund {
  display: flex;
  gap: 1rem;
}

.alternativeText {
  color: red;
}

.back {
  font-size: 18px;

  &Link {
    display: flex;
    align-items: center;

    span {
      margin-left: 12px;
    }
  }
}
