@import '../../../../styles/variables/colors';

.container {
  position: relative;
}

.button {
  height: 36px;
  margin-left: 50%;
  transform: translateX(-50%);
}

.dropdown {
  position: absolute;
  right: 0;
  top: 0;
}

.list {
  width: 167px;
}

.listItem {
  &:not(:last-child) {
    margin-bottom: 18px;
  }
}
