@use '~styles/variables' as *;

.timeSection {
  display: flex;
  flex-direction: column;

  .label {
    font-size: 20px;
    margin: 0 0 18px 5px;
  }
}

.timeButtons {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;

  button {
    border: 1px solid $color-romance;
    width: 55px;
    font-size: 28px;
    margin: 0 0 0 8px;
  }
}

.increment {
  button {
    padding: 2px 18px;
    border-radius: 50% 50% 0 0;
  }
}

.decrement {
  button {
    padding: 2px 20px;
    border-radius: 0 0 50% 50%;
  }
}

.timeDisplay {
  display: flex;
  align-items: center;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 55px;
    font-size: 28px;
    color: $color-white;
    margin: 7px;
  }

  .amOrPm {
    border: 1px solid $color-romance;
    border-radius: 10%;
  }
}
