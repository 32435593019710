@import '../../../styles/variables/colors.scss';
@import '../../../styles/variables/fonts.scss';

.container {
  z-index: 100;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(5px);
}

.wrapper {
  position: relative;
  background-color: rgba($gray5, 0.9);
  border-radius: 22px;
  padding: 40px;
  width: 637px;
}

.body {
  text-align: center;
}

.text {
  color: $romance;
  font-size: $header;
}

.buttons {
  margin-top: 40px;
  text-align: center;
}

.confirm {
  text-decoration: underline;
}

.cancel {
  margin-right: 50px;
  color: $romance;
  opacity: 0.5;
}
