@import '../../styles/variables/colors';

.container {
  display: flex;
  flex-direction: column;
  background: $gray5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.0784314);
  border-radius: 20px;
  padding: 28px 28px 30px 28px;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 28px;
}

.name {
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  color: rgba($romance, 0.85);
}

.small {
  font-size: 13px;
  line-height: 16px;
  color: rgba($romance, 0.4);
}

.text {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: rgba($romance, 0.85);
}

.link {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba($romance, 0.85);
  text-transform: uppercase;
  text-decoration: underline;
}

.wrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;

  svg {
    width: 15px;
    margin-right: 10px;

    g,
    path {
      fill: rgba($romance, 0.5);
    }
  }
}

.copy {
  margin-left: 13px;
}

.address {
  width: 190px;
}

.subscription {
  color: rgba($romance, 0.4);
  margin-top: 25px;
}
