@import 'styles/variables/colors.scss';

.bill {
  padding: 5px 20px 20px 20px;
  position: relative;
}

.billButton {
  left: -3px;
  position: absolute;
  cursor: pointer;
}

.billBlock {
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
}

.billText {
  font-size: 18px;
}

.billTotal {
  display: flex;
  font-size: 30px;
  margin-top: 5px;
}

.billCheckout {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  font-size: 22px;
  color: $romance;
  opacity: 1;
  text-decoration-line: underline;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: default;
    text-decoration-line: none;
  }
}

.taxBlock {
  padding-left: 5px;

  .billText {
    font-size: 16px;
  }
}

.totalWrapper {
  margin-top: 10px;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  justify-content: space-between;
}

.total {
  text-align: right;
  display: flex;
  gap: 1rem;
  align-items: flex-end;

  .billText {
    font-size: 22px;
  }
}

.couponContainer {
  margin-top: 15px;
  padding: 5px 10px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: row;

  input {
    border-bottom: none;
    font-size: 16px;
    flex-grow: 1;
  }

  div {
    margin-top: 0;
    flex-grow: 1;
  }

  button {
    background: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 5px;
  }
}

.spinner {
  transform: scale(0.5);
  margin: 0;
  margin-left: -15px;
  padding: 0;
}

.checkoutContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  vertical-align: top;
  height: 40px;
}
