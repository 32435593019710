.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.icon {
  margin-top: 30px;
}

.button {
  text-transform: uppercase;
  margin-top: 20px;
}
