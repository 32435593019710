@import 'styles/variables/colors';

.container {
  display: flex;
  align-items: center;
}

.text {
  margin-left: 20px;
  font-size: 16px;
  line-height: 16px;
  color: rgba($romance, 0.5);
}
