.root {
  position: fixed;
  top: -100vh;
  left: 0;
  z-index: 9990;
  height: 200vh;
  width: 100vw;
  padding-top: 100vh;
  background-color: black;
  overflow: auto;
}

.translucent {
  background: rgba($color: black, $alpha: 0.5);
}
