@use '~styles/variables' as *;

.root {
  display: flex;
  flex-direction: column;
}

.label {
  font-size: 14px;
  color: $color-romance;
}

.dropdown {
  margin-top: 10px;
  width: 100%;
}

.select {
  display: flex;
  align-items: center;
  border-bottom: thin solid #edeae3;
  padding: 8px 0;
  width: 100%;
  color: white;
  font-size: 28px;
}

.value {
  flex: 1 1 auto;
}

.placeholder {
  flex: 1 1 auto;
  color: #edeae3;
}

.listWrapper {
  border: thin solid $color-romance;
  background: #040404;
  border-radius: 20px;
  z-index: 9999;
}

.draggable {
  max-height: 300px;
}

.list {
  display: flex;
  flex-direction: column;
  padding: 20px 16px;

  &Item {
    color: rgba($color-romance, 0.8);
    font-size: 22px;
    text-align: center;
    transition: 0.2s;

    &:not(:first-child) {
      margin-top: 26px;
    }

    &:hover {
      color: white;
    }
  }
}

.icon {
  margin: 0 15px 0 0;
}
