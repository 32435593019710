@import '../../styles/variables/colors';

.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 19px;
  font-weight: bold;
  max-width: 250px;
}

.price {
  font-size: 16px;
  opacity: 0.5;
  margin-top: 3px;
}

.counterWrapper {
  display: flex;
  align-items: center;
}

.button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #1b1b1b;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
    border: thin solid rgba($romance, 0.2);
  }
}

.count {
  display: block;
  width: 75px;
  text-align: center;
  font-weight: bold;
}
