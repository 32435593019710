@import '../../../styles/variables/colors';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  line-height: 23px;
  font-size: 19px;
  font-weight: 400;
  color: $romance;
}

.totalPrice {
  padding-top: 20px;
  font-size: 30px;
  color: $romance;
}

.cardWrapper {
  margin-top: 54px;
  display: flex;

  & > * {
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
}

.card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 29px 31px;
  background-color: $codGray;
  border-radius: 15px;
  width: 196px;
  transition: 0.2s;
}

.activeCard {
  background-color: $tundora;
}

.cardValue {
  font-size: 30px;
  line-height: 34px;
  color: $romance;
  opacity: 0.85;
}

.cardSubtitle {
  font-size: 12px;
  line-height: 14px;
  color: $romance;
  opacity: 0.5;
}

.linkWrapper {
  margin-top: 70px;
}

.link {
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;

  &:not(:last-child) {
    margin-right: 32px;
    color: $romance;
    opacity: 0.5;
  }

  &:last-child {
    opacity: 0.85;
  }
}
