@use '~styles/variables' as *;

.root {
  display: flex;
  flex-direction: column;
  padding: 28px $dimension-dashboardHorizontalPadding;
  background: $color-black;
  height: 100%;
}

.back {
  margin-bottom: 15px;
  font-size: 18px;

  &Link {
    display: flex;
    align-items: center;

    span {
      margin-left: 12px;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 28px $dimension-dashboardHorizontalPadding;
  background: $color-black;
  height: 100%;
}

.leftBlock {
  width: 58%;
  padding-right: 10px;
}

.customerNote {
  height: 50px;
  margin-top: 25px;

  input {
    font-size: 20px;
    letter-spacing: initial;
    padding-left: 0;
    height: 100%;
  }

  input::placeholder {
    font-size: 18px;
    color: rgba($color: $color-romance, $alpha: 0.5);
  }
}

.addNoteButton {
  height: 100%;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0 10px;
  color: rgba($color: $color-romance, $alpha: 0.5);
}

.rightBlock {
  width: 40%;
}

.breadcrumbs {
  position: absolute;
  bottom: 10px;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.breadcrumbsCustomers {
  font-size: 15px;
  color: rgba($color: $color-romance, $alpha: 0.5);
  margin-right: 8px;
}

.breadcrumbsCustomer {
  font-size: 15px;
  font-weight: bold;
  margin-left: 8px;
}
