@import '../../../styles/variables/colors';
@import '../../../styles/variables/fonts';

.container {
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  backdrop-filter: blur(5px);
}

.wrapper {
  position: relative;
  background-color: rgba($black, 0.9);
  border-radius: 22px;
  padding: 40px;
  width: 600px;
}

.close {
  position: absolute;
  right: 40px;
  top: 30px;
}

.title {
  color: $romance;
  font-size: $title;
}

.body {
  display: flex;
  justify-content: space-around;
  padding: 30px 0;
}
