@import 'styles/variables/colors';

.disabled {
  opacity: 0.8;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 calc(33.333% - 1rem);
  max-width: calc(33.333% - 1rem);
  min-height: 200px;
  box-sizing: border-box;
  margin: 0 0 0.8rem 0;
  background: $tundora;
  border-radius: 15px;
  padding: 5px;
}

.info {
  flex-grow: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.6rem;
  margin-bottom: 10px;
  color: $white;
  flex-grow: 1;
}

.price {
  font-size: 1.25rem;
  font-weight: 500;
  opacity: 0.6;
}
