@import '../../../../styles/variables/colors.scss';

.paymentCard {
  padding: 25px 20px 20px;
  background-color: rgba($mineshaft, 0.85);
  border-radius: 15px;
  width: calc((100% - 21px) / 2);
  height: 175px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.2s;

  &:disabled {
    user-select: none;
    cursor: not-allowed;
    background-color: $mineshaft;
    opacity: 0.5;
  }
}

.paymentCardHeader {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  color: rgba($romance, 0.5);
}

.paymentCardItem {
  &:not(:last-of-type) {
    margin-right: 12px;
  }
}

.paymentCardInfo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paymentCardMethod {
  display: flex;
}

.paymentCardOrder {
  font-size: 10px;
}

.paymentCardName {
  font-size: 20px;
  margin-left: 15px;
  font-weight: bold;
  color: rgba($romance, 0.85);
  padding-bottom: 10px;
}
