.thermal {
  position: relative;
}

.print_panel {
  width: 576px;
  font: 30px arial, sans-serif;
  height: auto;
  position: absolute;
  margin-left: 95px;
  margin-top: 60px;
}

div.print_img {
  text-align: center;
  font: 30px arial, sans-serif;
  background: #000;
  padding: 30px 0;
  margin: 10px 10px 40px;
}

div.print_center {
  text-align: center;
  font: 30px arial, sans-serif;
}

div.print_row {
  width: 100%;
  min-height: 50px;
  clear: both;
}

div.mb {
  margin-bottom: 20px;
}

div.print_item_name {
  clear: left;
  float: left;
  padding-right: 30px;
  text-align: left;
  font: 30px arial, sans-serif;
  width: 62%;
}

div.print_item_qtn {
  float: left;
  font: 18px arial, sans-serif;
  line-height: 30px;
  border-radius: 15px;
  border: 2px solid #000;
  width: 30px;
  height: 30px;
  text-align: center;
}

div.print_item_price {
  clear: right;
  float: right;
  padding-right: 10px;
  text-align: right;
  font: 30px arial, sans-serif;
  width: 30%;
}

div.print_space {
  clear: left;
  padding: 0.3em;
}

div.big {
  font: 50px arial, sans-serif !important;
}

div.fr {
  float: right;
}

footer img {
  float: left;
}

hr {
  width: 100%;
  opacity: 1;
}

.bg_black {
  background: black;
  color: #fff;
}

.footer {
  padding-left: 3px;
  padding-top: 30px;
}

.tax_font * {
  font: 25px arial, sans-serif !important;
}

.info_font * {
  font: 20px arial, sans-serif !important;
}

.info_font b {
  font-weight: bold !important;
}

.col {
  width: 33%;
  float: left;
}

.sample {
  font: 20px arial, sans-serif !important;
  margin-bottom: 15px;
}
