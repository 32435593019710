@import 'styles/variables/colors';

.container {
  width: 100%;
  max-height: 100%;
  overflow: scroll;
}

.table {
  width: 100%;
  table-layout: fixed;
}

.head {
  padding-bottom: 15px;
  border-bottom: 2px solid rgba(237, 234, 227, 0.103174);
  font-size: 20px;
}

.checkCell {
  width: 140px;
}

.itemCell {
  width: 243px;
  text-align: left;
}

.priceCell {
  padding-right: 30px;
  text-align: right;
}

.checkbox {
  padding-left: 30px;
}

.emptyRow {
  height: 40px;
}
