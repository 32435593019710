@import 'styles/variables/colors.scss';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.error {
  font-size: 20px;
  text-align: center;
  margin: 100px 100px;
  padding: 30px;
  background-color: #0d0d0d;
}

.line {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.dots {
  margin-top: -4px;
}

.icon {
  margin-top: 20px;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.actionLink {
  text-transform: uppercase;
  text-align: center;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 16px;
  color: $romance;

  &:nth-child(2) {
    margin-left: 50px;
  }
}

.actionLinkMuted {
  color: rgba($romance, 0.5);
}

.actionLinkSmall {
  display: block;
  font-size: 10px;
}
