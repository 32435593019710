@import '../../styles/variables/colors';

.container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.disabledContainer {
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 999999;
}

.title {
  font-size: 25px;
  line-height: 28px;
  margin-bottom: 8px;

  span {
    margin-right: 12px;
  }
}

.additions {
  margin-top: 40px;
}

.chips {
  margin-top: 40px;
}

.chipsWrapper {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 15px);

  & > * {
    margin-right: 15px;
    margin-bottom: 15px;
  }
}

.subtitle {
  color: $white;
  font-size: 20px;
  margin-bottom: 20px;
}

.notes {
  margin-top: 40px;
}

.textarea {
  width: 430px;
  height: 200px;
  border-radius: 12px;
  background-color: $codGray;
  padding: 20px;
  font-size: 18px;
  line-height: 22px;
}

.button {
  padding: 12px 18px;
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  border: 1px solid $white;
  cursor: pointer;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1.27px;

  &:disabled {
    cursor: not-allowed;
  }
}

.keyboard {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  display: none;
}

.keyboardShow {
  display: block;
  z-index: 5;
}
