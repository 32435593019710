@use '~styles/variables' as *;

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.back {
  margin: 20px 30px;

  &Link {
    display: flex;
    flex-direction: row;

    span {
      margin-left: 12px;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 28px $dimension-dashboardHorizontalPadding;
}

.heading {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  margin-top: -60px;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon {
  margin-right: 1px;
}

.clockIcon {
  margin-top: -5px;
  margin-right: 1rem;
  height: 25px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: $color-white;
}

.changeButtonContainer {
  margin: 1rem 0 0 0;
}

.content {
  margin: 14px 0 0 0;
  line-height: 26px;
  width: 50%;
}

.helloMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: flex-start;
  justify-content: center;
  gap: 8px;
  line-height: 32px;
  margin: 0;
  padding-top: 12px;
  height: 6rem;

  .label {
    font-size: 24px;
    color: $color-white;
  }
}

.dateSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin: 0;

  .label {
    font-size: 28px;
    color: $color-white;
  }
}

.dateButton {
  border: 1px solid rgba($color-romance, 0.85);
  border-radius: 20px;
  font-size: 14px;
  color: rgba($color-romance, 0.85);
  text-transform: uppercase;
  padding: 10px 18px;
  white-space: nowrap;
}

.editIcon {
  margin-left: 1rem;
  height: 25px;
  color: $color-brand;
}

.timeSectionContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1rem 0;
  gap: 72px;
}

.timeSectionSingleContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1.5rem 0;
}

.picker {
  position: absolute;
  margin-top: 0;
  z-index: 9999;
}

.commentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 12rem;
}

.comment {
  margin: 1.5rem 0;
  width: 100%;
  font-size: 14px;
}

.errorMessage {
  color: red;
  font-size: 16px;
}

.messageContainer {
  text-align: center;
  height: 1.5rem;
}

.buttonSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.submit {
  background-color: $color-brand;
  color: $color-white;
  font-size: 20px;
  height: 43px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 35rem;
  text-align: center;
  margin-top: 15px;
}

.skip {
  font-size: 20px;
  height: 43px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  margin-top: 15px;
}

.select {
  border-radius: 8px;
  border: 1px solid $color-romance;
  padding: 20px 17px 20px 23px;
  margin-bottom: 1px;
  text-decoration: none;
  font-size: 22px;
  width: 22rem;
  height: 60px;
}

.selectDropdown {
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectOption {
  font-size: 22px;
  margin-top: 0;
  padding-top: 8px;
  padding-bottom: 8px;

  &:not(:first-child) {
    margin-top: 0;
  }
}

.selectDraggable {
  max-height: 190px;
}

.selectValue {
  margin-left: 22px;
  font-size: 22px;
}

.selectPlaceholder {
  font-size: 22px;
  margin-left: 22px;
  color: rgba($color-romance, 0.85);
}

.commentSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  width: 35rem;
}

.commentSectionNew {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  width: 35rem;
}
