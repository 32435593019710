@import 'styles/variables/colors';

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px 20px;
  width: calc(100% - 20px);
}

.card {
  margin-right: 20px;
  margin-bottom: 20px;
}
