@import 'styles/variables/colors.scss';
@import 'styles/variables/dimensions.scss';

.root {
  display: flex;
  padding: 22px $dashboardHorizontalPadding;
  background: $black;
  border-bottom: thin solid $romance;
}

.blocks {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.lockIcon {
  margin-right: 50px;
  height: 30px;
}

.logo {
  height: 45px;
  margin-right: 1rem;
}

.menu {
  display: flex;
  align-items: center;
}

.menuItem {
  font-size: 20px;

  &:not(:first-child) {
    margin-left: 2rem;
  }
}

.menuLink {
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: $doveGrey;
  font-family: 'RiformaLLWebTrial-MediumTrial', sans-serif;
  opacity: 0.7;
  transition: 0.2s;

  svg,
  svg path {
    transition: fill 0.2s;
    fill: $doveGrey;
  }
}

.icon {
  margin-right: 10px;
}

.menuActiveLink {
  opacity: 1;
  color: $white;

  svg,
  svg path {
    fill: $white;
  }
}

.info {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  .user {
    display: flex;
    align-items: center;
    font-size: 18px;
  }
}

.lock {
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-right: 2px;

  .logo {
    height: 25px;
  }
}

.logoutItem {
  font-size: 24px;
  line-height: 30px;
  padding: 4px 14px;

  &:hover {
    color: $brand;
  }
}

.logoutText {
  color: $red;
}
