@use '~styles/variables' as *;

.root {
  padding: 33px 26px;
  display: flex;
  justify-content: space-between;
}

.input {
  width: 60%;

  input {
    font-size: 30px;
    letter-spacing: -1px;
    padding: 24px 24px;
  }
}

.title {
  font-size: 22px;
  font-weight: bold;
  color: rgba($color-romance, 0.85);
}

.guests {
  width: 35%;

  &Header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &Actions {
      display: flex;
      align-items: center;
    }

    &Button {
      font-size: 14px;
      color: rgba($color-romance, 0.4);
      padding: 5px 12px;
      text-transform: uppercase;
      cursor: pointer;

      &:disabled {
        cursor: default;
        color: rgba($color-romance, 0.4);
      }
    }
  }

  &List {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    margin-top: 30px;
    max-height: 800px;
  }

  &Item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 26px 23px;
    border: 0.5px solid rgba($color-romance, 0.5);
    border-radius: 8px;

    &:not(:last-of-type) {
      margin-bottom: 8px;
    }
  }

  &Info {
    display: flex;
    align-items: flex-end;

    &Title {
      font-size: 14px;
      color: rgba($color-romance, 0.85);
      margin-right: 10px;
    }

    &Club {
      font-size: 12px;
      color: rgba($color-romance, 0.5);
    }
  }
}

.users {
  display: flex;
  flex-direction: column;
  height: 400px;
  overflow: scroll;
  margin-top: 10px;
  padding-bottom: 340px;
}

.user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;

  &Title {
    font-size: 28px;
    color: rgba($color-romance, 0.85);
  }

  &Email {
    font-size: 16px;
    color: rgba($color-romance, 0.65);
    margin-top: 0.5em;
  }

  &Button {
    padding: 5px 18px;
    height: 37px;
    width: 90px;
    font-size: 14px;
    text-transform: uppercase;
    color: rgba(white, 0.85);
    border-radius: 20px;
    border: 1px solid $color-mineshaft;
    background-color: $color-mineshaft;
    cursor: pointer;
  }
}

.buttonConfirm {
  border: 1px solid rgba($color-romance, 0.85);
  color: rgba($color: $color-romance, $alpha: 0.85);
  border-radius: 20px;
  margin-left: 32px;
  cursor: pointer;
}

.addGuestButton {
  font-size: 14px;
  color: rgba($color-romance, 0.85);
  margin-right: 32px;
  text-transform: uppercase;
  white-space: nowrap;
}

.createButton {
  font-size: 14px;
  color: rgba($color-romance, 0.85);
  text-transform: uppercase;
}

.buttonContainer {
  display: flex;
  align-items: center;
}
