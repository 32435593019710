@use '~styles/variables' as *;

$genericPadding: 26px;

.root {
  text-align: left;
  padding: $genericPadding 10px 18px 10px;
  color: $color-romance;
  font-size: 22px;

  &Inner {
    display: flex;
    align-items: center;
  }
}

.minimal {
  width: 0;
  white-space: nowrap;
}

.sortable {
  cursor: pointer;
}

.sortIcon {
  margin-right: 5px;
}
