@import '../../styles/variables/colors';

.container {
  background-color: $black;
  border: thin solid $romance;
  border-radius: 12px;

  & > * {
    font-size: 19px;
  }
}
