@use '~styles/variables' as *;

.root {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 0 auto;
  margin-top: 60px;

  @media screen and (max-width: $dimension-tablet) {
    width: 90%;
  }
}

.group {
  position: relative;

  &:not(:last-child) {
    margin-top: 32px;
  }
}

.meta {
  display: grid;
  grid-gap: 32px;
  margin-top: 32px;
  grid-template-columns: 0.4fr 0.25fr 0.35fr;
}

.merchants {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;

  & > div:not(:first-child) {
    margin-left: 6px;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
}

.confirm {
  margin-right: 36px;

  &Link {
    text-transform: uppercase;
    opacity: 0.75;
    font-size: 15px;
  }
}

.btnProceed {
  width: 37px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}
