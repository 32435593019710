@use '~styles/variables' as *;

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 80px;
}

.content {
  margin-top: 100px;
}

.close {
  display: flex;
  justify-content: flex-end;
}

.button {
  &Container {
    display: flex;
    align-items: center;
  }

  &Cancel {
    margin-right: 40px;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    color: rgba($color-romance, 0.65);
  }

  &Submit {
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    color: rgba($color-romance, 0.85);
  }
}
