@import '../../../styles/variables/colors.scss';

.report {
  background-color: $gray5;
  padding: 29px;
  margin-top: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.item {
  width: 30%;
}

.itemTitle {
  font-size: 15px;
  font-weight: bold;
  color: rgba($color: $romance, $alpha: 0.5);
  margin-bottom: 13px;
}

.itemValue {
  font-size: 19px;
  font-weight: bold;
  color: $romance;
}
