@import '../../../styles/variables/colors.scss';

.block {
  background-color: $gray5;
  border-radius: 20px;
  margin-top: 11px;
  overflow: hidden;
}

.title {
  padding: 25px 0 25px 25px;
  font-size: 19px;
  font-weight: bold;
  color: $romance;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba($color: $romance, $alpha: 0.1);
}

.titleNumber {
  margin-left: 8px;
  color: rgba($color: $romance, $alpha: 0.5);
  font-size: 16px;
}

.container {
  padding-left: 25px;
  height: calc(100% - 70px);
  overflow: scroll;
}
