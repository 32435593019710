@import '../../styles/variables/colors';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.icon {
  margin-right: 10px;
}

.list {
  min-width: 70px;
  margin-left: -20px;
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;

  button:last-child {
    position: sticky;
    bottom: -20px;
    left: 0;
    right: 0;
    padding: 15px 0;
    background: $lightBlack;
  }
}
