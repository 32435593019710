@use '~styles/variables' as *;

@import '../../styles/variables/colors.scss';

@font-face {
  font-family: 'RiformaTrial-Light';
  src: url('../../assets/fonts/Riforma/RiformaTrial-Light.otf')
    format('opentype');
}

@font-face {
  font-family: 'RiformaTrial-Medium';
  src: url(../../assets/fonts/Riforma/RiformaTrial-Medium.otf)
    format('opentype');
}

@font-face {
  font-family: 'RiformaTrial-Regular';
  src: url('../../assets/fonts/Riforma/RiformaTrial-Regular.otf')
    format('opentype');
}

html {
  overflow: hidden;
  height: 100%; // Ensure this does not cause content to be cut off
}

body {
  display: flex;
  min-height: 100vh;
  background-color: $black;
  font-family: Helvetica, Arial, sans-serif;
  //font-family: "RiformaTrial-Regular", sans-serif;
}

* {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

*::-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
  width: 0;
  height: 0;
}

.app {
  display: flex !important;
  flex-direction: column;
  min-width: 100vw;
  height: 100vh;
  color: $white;
}

.updateTitle {
  font-size: 24px;
  font-weight: bold;
  color: $color-white;
  padding-bottom: 10px;
}

.update {
  background-color: $color-brand;
  color: $color-white;
  font-size: 20px;
  height: 43px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  margin-top: 15px;
}
