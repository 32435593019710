@import '../../../styles/variables/colors';

.container {
  color: $white;
  position: relative;
  padding: 60px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.title {
  font-size: 36px;
  margin: 0 0 60px 0;
}

.form {
  margin-top: 60px;
}

.close {
  position: absolute;
  right: 40px;
  top: 40px;
  z-index: 5;
}

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(1.2);
}
