@import 'styles/variables/colors';

.root {
  padding: 33px 26px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.cart {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 22px;
}

.checkout {
  width: 60%;
  margin-left: 64px;
}

.backButton {
  display: flex;
  gap: 1rem;
  font-size: 18px;
  color: rgba($romance, 0.85);
}
