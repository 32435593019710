@use '~styles/variables' as *;

.root {
  display: flex;
  flex-direction: column;
  background: $color-gray5;
  border-radius: 20px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: thin solid rgba($color-romance, 0.5);
  width: 100%;

  &Title {
    font-size: 16px;
    color: rgba($color-romance, 0.4);
    width: 27%;
    padding: 26px 0;
    text-align: center;

    &:last-child {
      width: 19%;
    }
  }

  &Caption {
    font-size: 14px;
    color: rgba($color-romance, 0.4);
  }
}

.list {
  position: relative;

  &Container {
    display: flex;
    flex-direction: column;
    max-height: 390px;
    overflow: auto;
    padding: 18px 0;
  }

  &Backdrop {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 90%,
      rgba(0, 0, 0, 0.8) 100%
    );
    z-index: 2;
    pointer-events: none;
    user-select: none;
  }

  &Item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &Description {
      font-size: 16px;
      color: rgba($color-romance, 0.85);
      width: 27%;
      padding: 16px 3px;
      text-align: center;
      word-break: break-word;
    }

    &Description:last-child {
      width: 19%;
    }
  }
}

.btn {
  cursor: pointer;
}
