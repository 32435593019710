@import '../../../styles/variables/colors.scss';

.block {
  height: 250px;
}

.item {
  height: 63px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid rgba($color: $romance, $alpha: 0.1);
  overflow: scroll;
}

.itemDate {
  width: 30%;
  color: rgba($color: $romance, $alpha: 0.5);
}

.itemPrice {
  width: 20%;
}

.itemStatus {
  width: 30%;
  color: rgba($color: $romance, $alpha: 0.5);
}

.itemType {
  color: rgba($color: $romance, $alpha: 0.5);
  text-transform: uppercase;
}
