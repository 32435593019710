@import '../../styles/variables/colors';

.container {
  position: relative;
  transition: 0.2s;
  scroll-behavior: smooth;
}

.activeContainer {
  transform: translateY(-140px);
}

.title {
  font-size: 26px;
  color: rgba($romance, 0.85);
  margin-bottom: 40px;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 40px);
}

.button {
  padding: 15px;
  width: 240px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 30px;
  border: 1px solid $white;
  cursor: pointer;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1.27px;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.keyboard {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  display: none;
}

.keyboardShow {
  display: block;
  z-index: 5;
}

.longField {
  width: calc(100% * 2 / 3 - 40px);
  margin-bottom: 30px;
  margin-right: 40px;

  input {
    width: 100%;
  }
}

.placeholder {
  opacity: 0.6;
}

.field {
  width: calc(100% / 3 - 40px);
  margin-right: 40px;

  &:not(:last-child) {
    margin-bottom: 40px;
  }
}

.selectContainer {
  border-color: $white;
  background-color: $black;
  height: 48px;
  font-size: 22px;
  color: rgba($romance, 0.85);
}
