@use '~styles/variables' as *;

.root {
  display: flex;
  flex-direction: column;
  padding: 5px $dimension-dashboardHorizontalPadding;
  flex: 1;
}

.topbar {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.content {
  margin-top: 10px;
  flex: 1;
}

.rangeFilters {
  display: flex;
  align-items: flex-start;
  margin-top: 1rem;
}

.filterMargin {
  margin-left: 40px;
}

.priceFilters {
  margin-left: 7rem;
  margin-top: -8px;
  width: 260px;
}

.inputRange {
  transform: translateY(8px);
}

.reset {
  margin: auto 0 auto 4px;
  opacity: 0.7;
  transform: translateY(18px);

  svg {
    width: 18px;
    height: 18px;
  }
}

.input {
  width: 110px;
  padding: 12px 12px 12px 0;
  border-bottom: thin solid transparentize($color-romance, 0.5);
  color: $color-romance;
  font-size: 20px;

  &::placeholder {
    opacity: 0.5;
  }
}

.totalDivider {
  margin: 0 15px;
}
