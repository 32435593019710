@use '~styles/variables' as *;

.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 37px;
  padding-bottom: 43px;
}

.form {
  display: flex;
  flex-direction: column;
}

.group {
  display: flex;
  width: 419px;
  flex-direction: column;
  margin-top: 32px;

  &:first-child {
    margin-top: 39px;
  }

  label {
    margin-bottom: 15px;
  }
}

.copyright {
  font-size: 10px;
}

.button {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.btnWrapper {
  display: flex;
  justify-content: center;
  margin-top: 56px;
}

svg circle {
  fill: transparent;
  transition: fill 0.25s ease;
}

.select {
  border-radius: 8px;
  border: 1px solid $color-romance;
  padding: 20px 17px 20px 23px;
  margin-bottom: 1px;
  text-decoration: none;
  font-size: 12px;
  width: 100%;
  height: 60px;
}

.selectDropdown {
  margin-top: 0;
}

.selectOption {
  font-size: 16px;
  margin-top: 0;
  padding-top: 8px;
  padding-bottom: 8px;

  &:not(:first-child) {
    margin-top: 0;
  }
}

.selectDraggable {
  max-height: 190px;
}

.selectValue {
  margin-left: 22px;
  font-size: 14px;
}

.selectPlaceholder {
  font-size: 14px;
  margin-left: 22px;
  color: rgba(#edeae3, 0.5);
}

.btn {
  padding: 12px 35px;
  color: $color-white;
  font-size: 14px;
  border-radius: 4px;
  margin-top: 50px;
  cursor: pointer;
  text-align: center;
}

.buttonPrimary {
  background: $color-brand;
}

.buttonPrimary:hover {
  background: lighten($color-brand, 5%);
}

.hr {
  width: 100%;
  color: $color-white;
  margin-top: 50px;
  opacity: 0.7;
}

.link {
  color: $color-white;
  font-size: 14px;
  text-align: center;
  margin-top: 30px;

  span {
    text-decoration: underline;
    font-weight: bold;
  }

  span:hover {
    cursor: pointer;
  }
}
