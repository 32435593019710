@import '../../styles/variables/colors';

.container {
  position: relative;
}

.inputContainer {
  display: block;
  border-bottom: thin solid $white;
  position: relative;
  width: 100%;
}

.input {
  padding: 12px 12px 12px 0;
  font-size: 22px;
  width: 100%;

  &::placeholder {
    opacity: 0.6;
  }
}

.dropdown {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  right: 0;
  background-color: $gray5;
  padding: 12px 16px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  max-height: 150px;
  overflow: scroll;
  z-index: 5;
}

.dropdownItem {
  padding: 8px 0;
  font-size: 18px;
  opacity: 0.6;
  width: 100%;

  &:hover {
    opacity: 1;
  }
}
