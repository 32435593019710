@use '~styles/variables' as *;

.root {
  padding: 40px 80px;
  position: relative;
  width: 100%;
  min-height: 100vh;
}

.stepper {
  display: flex;
  align-items: center;
  justify-content: center;

  .step {
    height: 1px;
    width: 10px;
    background: rgba($color-romance, 0.3);

    &Active {
      background: $color-romance;
    }

    &:not(:first-child) {
      margin-left: 22px;
    }
  }
}

.content {
  margin-top: 60px;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.codeTitle {
  font-size: 30px;
  color: rgba($color-romance, 0.85);
  width: 300px;
}

.verifiedContainer {
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.verifiedTitle {
  font-size: 28px;
  line-height: 36px;
  color: rgba($color-romance, 0.85);
  text-transform: uppercase;
  text-align: center;
  width: 70%;
}

.button {
  width: 160px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 26px;

  span {
    font-size: 18px;
  }
}

.close {
  margin-left: auto;
}

.back {
  svg {
    transform: rotate(180deg);
  }
}

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(1.2);
}
