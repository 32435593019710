@use '~styles/variables' as *;

$iconGap: 18px;

.root {
  display: flex;
  align-items: center;
  position: relative;
}

.icon {
  position: absolute;
  right: $iconGap;
}

.input {
  width: 100%;
  padding: 12px $iconGap;
  padding-left: 0;
  border-bottom: thin solid transparentize($color-romance, 0.5);
  color: $color-romance;
  font-size: 20px;

  &::placeholder {
    opacity: 0.5;
  }
}
