.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  -webkit-transition: opacity 250ms;
  transition: opacity 250ms;
}

.fade-appear {
  opacity: 0;
}

.fade-appear-active {
  opacity: 1;
  -webkit-transition: opacity 250ms;
  transition: opacity 250ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  -webkit-transition: opacity 250ms;
  transition: opacity 250ms;
}

.loader_wrapper {
  height: 100% !important;
}

.customProgressBarSuccess {
  background: #4caf50;
}

.customProgressBarError {
  background: #f44336;
}

.customProgressBar {
  background: #ddd;
}

.customProgressBarInfo {
  background: #2196f3;
}
