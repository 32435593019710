@import '../../../styles/variables/colors';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.title {
  line-height: 1.15;
  font-size: 30px;
  font-weight: 400;
  color: $romance;
  text-transform: uppercase;
}

.button {
  position: fixed;
  bottom: 40px;
  right: 40px;
  font-size: 16px;
  font-weight: 300;
  color: $romance;
  opacity: 0.5;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 24px 48px;

  &:hover {
    opacity: 0.7;
  }
}
