@use '~styles/variables' as *;

$genericPadding: 26px;

.root {
  text-align: left;
  padding: $genericPadding 10px 18px 10px;
  color: $color-romance;
  font-size: 22px;

  &Inner {
    display: flex;
    align-items: center;
    position: relative;
  }
}

.minimal {
  width: 0;
  white-space: nowrap;
}

.sortable {
  cursor: pointer;
}

.sortIcon {
  position: absolute;
  width: 20px;
  left: -13px;
}
