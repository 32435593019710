@use '~styles/variables' as *;

@import 'styles/variables/colors.scss';

.container {
  margin-bottom: 0.5rem;
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: start;
  color: $color-romance;
  width: 100%;
}

.infoMainInformation {
  display: flex;
  flex-direction: column;
}

.infoTitleBlock {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.infoDeleteButton {
  width: 18px;
  cursor: pointer;
  margin-right: 22px;
  margin-top: 5px;

  svg {
    width: 18px;
    height: 18px;
  }
}

.infoEditButton {
  cursor: pointer;
  margin-left: 10px;
  margin-top: 5px;
  height: 15px;
  display: flex;
  align-items: center;
}

.infoTitle {
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.5px;
}

.priceBlock {
  display: flex;
  gap: 1rem;
  justify-self: end;
}

.price {
  font-size: 18px;
  text-align: left;
  letter-spacing: 0.5px;
}

.oldPrice,
.additionOldPrice {
  font-size: 18px;
  opacity: 0.6;
  text-decoration: line-through;
}

.description {
  width: 100%;
  font-size: 16px;
  letter-spacing: 0.7px;
  line-height: 17px;
  margin-left: 40px;
  display: flex;
  justify-content: space-between;
  justify-self: end;
}

.descriptionCheckout {
  margin-left: 0;
}

.addition {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 8px;
}

.additionTitle {
  width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: right;
  font-size: 16px;
  line-height: 17px;
  margin-left: 30px;
  color: rgba($color-romance, 0.9);
}

.additionPriceWrapper {
  text-align: right;
}

.additionPrice {
  font-size: 16px;
  line-height: 17px;
  margin-left: 15px;
  color: rgba($color-romance, 0.9);
}

.cartButtonsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.cartButton {
  font-size: 20px;
  width: 3.5rem;
  margin-top: -0.2rem;
  height: 3.5rem;
  border-radius: 50%;
  background-color: #1b1b1b;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
    border: thin solid rgba($romance, 0.2);
  }
}

.descriptionItem {
  align-self: center;
  margin: 0 0 0 40px;

  span {
    margin: 0 18px;
  }
}
