@import 'styles/variables/colors.scss';

.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 26px;
  padding: 0 25px;
}

.breadcrumbs {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 26px;
  font-size: 18px;
}

.categories {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  max-height: 600px;
}

.sidebar {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: -8px 0 0 0;
  min-height: 300px;
}

.sidebarContent {
  background: $gray5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.0784314);
  border-radius: 20px;
  padding: 20px 20px;
  transition: opacity 0.25s;
}

.sidebarHeading {
  font-size: 20px;
  line-height: 22px;
  margin: 4px 0 20px 0;
}

.sidebarContentItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 26px;
  font-size: 18px;
  opacity: 0.85;
  margin-bottom: 10px;
}

.sidebarContentItemTotalContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.sidebarContentItemTotal {
  display: flex;
  gap: 10px;
  padding: 10px;
  margin: 0 0.6rem 0 0;
  font-size: 20px;
  width: fit-content;
  color: #f5f5f5;
}

.breadcrumb {
  display: flex;

  &Title {
    &:not(:last-child) {
      opacity: 0.5;
      cursor: pointer;
    }

    &:last-child {
      font-weight: bold;
      opacity: 0.85;
    }
  }

  &Spacer {
    margin: 0 10px;
    opacity: 0.5;
  }
}

.inner {
  margin-top: 25.5px;
  flex: 1 1 auto;
}

.sidebarContentItemTitle {
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.5px;
}

.sidebarContentItemValue {
  line-height: 22px;
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }

  @-moz-keyframes #{$animation-name} {
    @content;
  }

  @-ms-keyframes #{$animation-name} {
    @content;
  }

  @-o-keyframes #{$animation-name} {
    @content;
  }

  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@include keyframes(slide-down) {
  0%,
  to {
    transform: rotate(0);
  }

  10% {
    transform: scale(1.1);
  }

  20% {
    transform: rotate(4deg) translateY(-12px);
  }

  40% {
    transform: rotate(2deg) translateY(0);
  }

  60% {
    transform: rotate(-2deg) translateY(-8px);
  }

  80% {
    transform: rotate(-1deg) translateY(0);
  }
}

.sidebarContentItemValueAnimation {
  @include animation('slide-down 1s 1');
}
