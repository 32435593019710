.keyboard {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  display: none;
}

.keyboardShow {
  display: block;
  z-index: 9999;
}
