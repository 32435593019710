@use '~styles/variables' as *;

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: opacity 0.25s;
}

.breadcrumbs {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 26px;
  font-size: 18px;
}

.breadcrumb {
  display: flex;
  flex-direction: row;

  &Title {
    &:not(:last-child) {
      opacity: 0.5;
      cursor: pointer;
    }

    &:last-child {
      font-weight: bold;
      opacity: 0.85;
    }
  }

  &Spacer {
    margin: 0 10px;
    opacity: 0.5;
  }
}

.inner {
  margin-top: 26px;
}
