@import '../../../styles/variables/colors';

.root {
  padding: 20px;
  text-align: center;
}

.close {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.totalPriceText {
  font-size: 18px;
  margin-bottom: 10px;
  color: $romance;
}

.totalPrice {
  font-size: 18px;
  margin-bottom: 30px;
  margin-top: 20px;
  color: $romance;
}

.tipDisplay {
  font-size: 28px;
  text-align: center;
  font-weight: bold;
  padding: 20px;
  color: $romance;
}

.customTipsSelect {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 100px;
}

.btn {
  height: 60px;
  width: 120px;
  text-align: center;
  margin-top: 200px;
  font-size: 27px;
  border: 2px solid $romance;
  background-color: transparent;
  color: $romance;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s;

  &:hover {
    background-color: $lightBlack;
  }

  &Active {
    background-color: $lightBlack;
  }
}

.keypadContainer {
  display: flex;
  justify-content: center;
  width: fit-content;
  padding: 10px;
  margin: 0 auto;
  margin-top: 20px;
}

.keypad {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 4px;
}

.keypadButton {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 27px;
  background-color: $lightBlack;
  color: $romance;
  border: 1px solid $romance;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;

  &:hover {
    background-color: lighten($lightBlack, 10%);
  }

  &:active {
    background-color: darken($lightBlack, 10%);
  }
}

.keypadButtonLabel {
  font-size: 16px;
  margin-top: 4px;
  color: $romance;
}

.confirmButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.confirmButton {
  max-width: 300px;
  width: 100%;
  padding: 15px;
  font-size: 18px;
  color: #fff;
  background-color: $brand;
  border: none;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: darken($brand, 10%);
  }
}

.totalWithTipContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: $romance;
}

.totalWithTip {
  max-width: 300px;
  width: 100%;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  background-color: $gray5;
  border: none;
  border-radius: 4px;
  text-align: center;
}

.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.link {
  font-size: 18px;
  padding: 10px 20px;
  color: $romance;
  background-color: transparent;
  border: 1px solid $romance;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: $lightBlack;
  }
}
