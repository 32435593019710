@use '~styles/variables' as *;

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 45px;
}

.title {
  font-size: 22px;
  margin-top: 60px;
  text-align: center;
}

.tips {
  display: grid;
  grid-template-columns: repeat(4, minmax(100px, 1fr));
  grid-gap: 40px;
  padding: 0 60px;
  margin-top: 60px;
}

.actions {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

.actionLink {
  text-transform: uppercase;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 13px;
  color: $color-romance;

  &Muted {
    color: rgba($color-romance, 0.5);
  }
}

.back {
  display: flex;
  align-items: center;
  margin-top: 80px;

  &Content {
    margin-left: 40px;
    text-transform: uppercase;
    font-size: 12px;
  }
}

.btn {
  height: 32px;
  width: 32px;
  border: thin solid $color-romance;
  border-radius: 100%;
  font-size: 14px;
  text-align: center;
  transition: 0.25s;

  &Active {
    background: $color-romance;
    color: black;
  }
}

.email {
  width: 600px;
  margin: 30px auto 0 auto;
}
