@import '../../styles/variables/colors';

.container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid $romance;
  padding-top: 20px;
}

.image {
  object-fit: cover;
  height: 15px;
}
