@import '../../../styles/variables/colors.scss';

.controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 12px;
  padding: 0 20px;
  overflow: scroll;
}

.sliderLines {
  display: flex;
}

.dot {
  cursor: pointer;
  padding: 5px 0;
  width: 18px;

  &:not(:first-child) {
    margin-left: 12px;
  }
}

.dotInner {
  height: 1px;
  width: 18px;
  background: $romance;
  opacity: 0.4;
}

.dotActive > .dotInner {
  opacity: 1;
}

.dot:hover .dotInner {
  opacity: 1;
}
