@import 'styles/variables/colors';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1 1 calc(33.333% - 1rem);
  max-width: calc(33.333% - 1rem);
  min-height: 200px;
  margin: 0 0 0.8rem 0;
  padding: 15px;
  background-color: $doveGrey;
  border-radius: 15px;
}

.count {
  font-size: 1.25rem;
  position: absolute;
  top: 20px;
  right: 18px;
}

.title {
  max-width: 100%;
  max-height: 60px;
  line-height: 1.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.2rem;
}
