@use '~styles/variables' as *;

.root {
  display: flex;
  flex-direction: column;
  padding: 28px $dimension-dashboardHorizontalPadding;
  flex: 1;
}

.calendar {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.date {
  font-size: 20px;
}

.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addButton {
  border: 1px solid rgba($color-romance, 0.85);
  border-radius: 20px;
  font-size: 14px;
  color: rgba($color-romance, 0.85);
  text-transform: uppercase;
  padding: 5px 18px;
  white-space: nowrap;
}

.content {
  margin-top: 30px;
  flex: 1;
}
