@import '../../styles/variables/colors';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.label {
  color: rgba($romance, 0.85);
  margin-bottom: 8px;
}

.textarea {
  width: 100%;
  min-height: 120px;
  border-radius: 12px;
  background-color: $codGray;
  padding: 20px;
  font-size: 20px;
  line-height: 22px;
  color: rgba($romance, 0.85);

  &::placeholder {
    color: rgba($romance, 0.5);
  }
}
