.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 24px;
  margin-top: 20px;
}

.actions {
  margin-top: 24px;
}

.price {
  font-size: 36px;
}

.button {
  padding: 10px 24px;
  background: transparent;
  border: thin solid white;
  border-radius: 20px;
  font-size: 16px;
  color: white;
  transition: 0.2s;

  &:hover {
    color: black;
    border-color: black;
    background: white;
  }

  &:not(:first-child) {
    margin-left: 20px;
  }

  &:disabled {
    opacity: 0.75;
    user-select: none;
    pointer-events: none;
  }
}
