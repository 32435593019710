@use '~styles/variables' as *;

$genericPadding: 26px;
$paginationGap: 29px;

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.table {
  width: 100%;
}

.translucentBodyCell {
  opacity: 0.4;
}

.bodyRow {
  border-top: 1px solid rgba(237, 234, 227, 0.1);

  a {
    padding-left: 0;
    font-size: 18px;
  }
}

.headCell {
  text-align: left;
  padding: $genericPadding 10px 18px 10px;
  color: $color-romance;
  cursor: pointer;
}

.bodyCellRow {
  display: flex;
  align-items: center;
  width: 0;
}

.statusCell {
  white-space: pre;
}
