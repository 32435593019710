@import '../../../styles/variables/colors';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  position: relative;
  display: block;
  width: 640px;
  background-color: $black;
  color: $romance;
  border-radius: 20px;
  padding: 40px 30px;
  transition: 0.2s;
}

.activeWrapper {
  margin-top: -300px;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 0;
  top: 0;
  padding: 30px;
}

.title {
  font-size: 22px;
  line-height: 26px;
  font-weight: bold;
  max-width: 350px;
}

.content {
  margin-top: 50px;
  max-height: 450px;
  overflow: scroll;
}
