@import 'styles/variables/colors';

.container {
  background-color: $brand;
  color: $white;
  font-size: 16px;
  height: 43px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  margin-top: 15px;
}

.disabledContainer {
  background: $codGray;
  color: $romance;
  cursor: not-allowed;
  opacity: 0.65;
}
