@use '~styles/variables' as *;

.root {
  display: flex;
  flex-direction: column;
  padding: 28px $dimension-dashboardHorizontalPadding;
  flex: 1;
}

.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content {
  margin-top: 30px;
  flex: 1;
}
