@import 'styles/variables/colors';

.blockContainer {
  background: $gray5;
  border-radius: 20px;
  padding: 26px;
}

.orderId {
  font-weight: 600;
  font-size: 20px;
}

.info {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.infoTitle {
  color: rgba($romance, 0.5);
  text-transform: uppercase;
  font-size: 18px;
}

.infoContent {
  display: flex;
  color: $romance;
  margin-top: 8px;
}

.badgeDraft {
  color: rgba($romance, 0.7);
  background: rgba($romance, 0.2);
  font-size: 18px;
}

.commentWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 105px;
  overflow: scroll;
}

.comment {
  margin-bottom: 16px;
  letter-spacing: 0.3px;
  color: rgba($romance, 0.85);

  &:last-child {
    margin-bottom: 0;
  }
}
