@import 'styles/variables/colors.scss';

.root {
  position: relative;
}

.icon {
  margin-left: 10px;
}

.list {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: $lightBlack;
  z-index: 9999;
}

.listItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  cursor: pointer;
}

.listLabel {
  color: $romance;
  letter-spacing: -0.08px;
  font-size: 22px;
  text-align: center;

  &:not(:first-child) {
    margin-top: 1rem;
  }
}

.buttonContainer {
  background-color: $brand;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  font-size: 22px;
  font-weight: 300;
  height: 2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  margin-top: 0.3rem;
  padding: 2rem;

  p {
    color: $white;
  }
}
