@import '../../../styles/variables/colors.scss';

.card {
  margin-top: 20px;
  padding: 23px 27px;
  border-radius: 20px;
  background-color: rgba($gray5, 0.85);
  font-family: 'RiformaTrial-Regular', sans-serif;
}

.wineclub {
  color: rgba($romance, 0.4);
  font-size: 15px;
  font-weight: 500;
}

.icon {
  width: 35px;
  height: 35px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 11px;
}

.body {
  margin-bottom: 30px;
}

.title {
  font-size: 24px;
  color: rgba($romance, 0.85);
}

.text {
  margin-top: 4px;
  font-size: 13px;
  color: rgba($romance, 0.4);
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 30px;
  border: 1px solid rgba($romance, 0.85);
  border-radius: 20px;
  font-size: 12px;
  color: rgba($romance, 0.85);
  text-transform: uppercase;
  width: 120px;
  height: 33px;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.active {
  border: none;
  background-color: #aeaca7;
  color: #0b0b0b;
}

.addressText {
  font-size: 16px;
  color: rgba($romance, 0.55);
  margin-top: 15px;
  display: block;
}
