@import '../../../styles/variables/colors.scss';

.card {
  background-color: $gray5;
  border-radius: 20px;
  padding: 22px;
  width: 100%;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;
}

.headerTitle {
  font-size: 19px;
  font-weight: bold;
}

.headerButton {
  text-decoration: underline;
  color: $romance;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  margin-top: 8px;
}

.wineclub {
  font-size: 14px;
  color: rgba($color: $romance, $alpha: 0.5);
}

.info {
  margin-top: 28px;
  font-size: 15px;
  font-weight: bold;
}

.infoEmail {
  margin-bottom: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.infoButton {
  margin-left: 19px;
}

.infoAddress {
  width: 70%;
}
