@use '~styles/variables' as *;

$iconGap: 18px;

.root {
  display: flex;
  align-items: center;
  position: relative;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border-color: $color-romance;
    box-shadow: 0 0 0 50px $color-black inset;
    -webkit-text-fill-color: white;
    transition: background-color 5000s ease-in-out 0s;
  }
}

.icon {
  position: absolute;
  left: $iconGap;

  svg {
    fill: $color-romance;
  }
}

.input {
  width: 100%;
  height: 60px;
  padding: $iconGap 22px;
  padding-left: 61px;
  border: thin solid;
  border-radius: 8px;
  color: $color-romance;
  font-size: 14px;
  margin-top: 6px;

  &::placeholder {
    opacity: 0.5;
  }
}
