@use '~styles/variables' as *;

$paginationGap: 29px;
$genericPadding: 26px;

.root {
  display: flex;
  justify-content: space-between;
  padding: 0 $genericPadding;
  margin-bottom: 12px;
}

.entries {
  color: $color-romance;
}

.pages {
  display: flex;
  align-items: center;
}

.control {
  color: $color-romance;

  &Disabled {
    color: rgba($color-romance, 0.3);
  }

  &Right {
    margin-left: $paginationGap;
  }
}

.page {
  margin-left: $paginationGap;
  color: rgba($color-romance, 0.5);

  &Active {
    color: $color-romance;
  }
}
