@use '~styles/variables' as *;

.root {
  display: flex;
  flex-direction: column;
}

.caption {
  font-size: 22px;
  color: rgba($color-romance, 0.85);
}

.error {
  margin-top: 30px;
  font-size: 24px;
  color: red;
}

.content {
  margin-top: 60px;

  input {
    font-size: 40px;
    letter-spacing: -1.35px;
  }
}
