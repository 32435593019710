@import '../../../styles/variables/fonts';
@import '../../../styles/variables/colors';

.container {
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(5px);
}

.wrapper {
  position: relative;
  background-color: rgba($gray5, 0.9);
  border-radius: 22px;
  padding: 40px;
  width: 637px;
}

.title {
  color: $romance;
  font-size: $header;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input {
  margin-top: 50px;
  width: 308px;

  input {
    font-size: 30px;
    padding: 12px 12px 12px 0;
    letter-spacing: -0.17px;
    text-transform: uppercase;
    text-align: center;

    &::placeholder {
      font-size: 20px;
    }
  }

  svg {
    display: none;
  }
}

.changeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
}

.change {
  font-size: 30px;
  color: white;
  font-weight: 500;
}

.actions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -10px;
}

.grayLink {
  color: $romance;
  opacity: 0.5;
}

.link {
  text-decoration: underline;
}

.grayText {
  font-size: 14px;
  color: $romance;
  opacity: 0.5;
  margin-top: 7px;
  text-transform: uppercase;
}

.close {
  position: absolute;
  right: 30px;
  top: 20px;
}
