@import '../../styles/variables/colors.scss';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.line {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.dots {
  margin-top: -4px;
}

.icon {
  margin-top: 20px;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
}

.actionLink {
  text-transform: uppercase;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 13px;
  color: $romance;

  &Muted {
    color: rgba($romance, 0.5);
  }

  &:nth-child(2) {
    margin-left: 50px;
  }
}
