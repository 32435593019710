@use '~styles/variables' as *;

.root {
  display: flex;
}

.rootLineDisabled {
  display: flex;
}

.caption {
  padding-top: 48px;
}

.input {
  white-space: nowrap;
  overflow: hidden;
  flex-grow: 1;

  input {
    width: 100%;
    border: none;
    background: transparent;
    color: rgba($color-romance, 0.85);
    font-size: 90px;
    letter-spacing: -4.35px;
    padding: 24px 24px;

    &.inputCentered {
      text-align: center;
    }
  }
}

.keyboard {
  display: none;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;

  &Shown {
    display: block;
    z-index: 5;
  }
}

.proceed {
  display: flex;
  align-items: center;
}
