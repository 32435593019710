.root {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
}

.content {
  display: flex;
  width: 50%;
  height: 645px;
  overflow: scroll;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.sidebar {
  width: 50%;
}
