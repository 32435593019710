@import '../../../styles/variables/colors';

.container {
  position: absolute;
  right: 19px;
  top: 19px;
  display: flex;
  align-items: center;
}

.button {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: thin solid $romance;

  &:not(:last-child) {
    margin-right: 10px;
  }

  svg {
    height: 12px;

    path {
      fill: $white;
    }
  }

  &:hover,
  &:focus {
    background-color: $romance;

    svg path {
      fill: $black;
    }
  }
}
