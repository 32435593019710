@import 'styles/variables/colors.scss';

.root {
  width: 100%;
}

.cartWrapper {
  position: relative;
  border-radius: 20px;
  margin-top: 10px;
  overflow: scroll;
}

.cart {
  height: 360px;
  background: $gray5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.0784314);
  padding: 20px 20px 46px 20px;
  overflow: scroll;
  position: relative;
}

.cartItems {
  height: 100%;
  overflow: auto;

  li {
    margin-bottom: 0.4rem;
  }
}

.cartItem {
  padding-bottom: 0.3rem;
}

.cartClearButton {
  position: absolute;
  bottom: 12px;
  right: 20px;
  border: 1px solid rgba($romance, 0.85);
  border-radius: 20px;
  font-size: 16px;
  color: rgba($romance, 0.85);
  text-transform: uppercase;
  padding: 7px 21px;
  background-color: black;
  z-index: 1;
}

.cartFooter {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  background: $gray5;
}

.cartButton {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: rgba($romance, 0.85);
}

.cartButtonBadge {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 26px;
  height: 26px;
  font-size: 18px;
  background-color: $mineshaft;
  color: $white;
  margin-right: 12px;

  svg {
    fill: none;
  }
}

.empty {
  text-align: center;
  padding-top: 54px;
  color: rgba($romance, 0.5);
}

.modal {
  opacity: 0.9;
}

.content {
  margin-top: 100px;
}

.inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 80px;
}
