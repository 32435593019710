@use '~styles/variables' as *;

.root {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.close {
  display: flex;
  justify-content: flex-end;
}

.content {
  position: relative;
  display: block;
  width: 524px;
  max-height: 440px;
  background-color: black;
  color: $color-romance;
  border-radius: 20px;
  padding: 40px 30px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 22px;
  line-height: 26px;
  font-weight: bold;
  max-width: 350px;
}

.options {
  display: block;
  margin-top: 36px;
}

.option {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 18px;

  &LeftBlock {
    display: flex;
    flex-direction: column;
  }

  &RightBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &Title {
    font-size: 19px;
    font-weight: bold;
    max-width: 250px;
  }

  &Price {
    font-size: 16px;
    opacity: 0.5;
    margin-top: 3px;
  }

  &Button {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #1b1b1b;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
      border: thin solid rgba($color-romance, 0.2);
    }
  }

  &Count {
    display: block;
    width: 75px;
    text-align: center;
    font-weight: bold;
  }

  &Inactive {
    .optionTitle {
      opacity: 0.5;
    }

    .optionCount {
      opacity: 0.5;
    }
  }
}

.continueButton {
  text-align: right;
  margin-top: 50px;
  color: $color-romance;
  font-size: 17px;
  text-transform: uppercase;
  text-decoration-line: underline;
  cursor: pointer;
}

.counter {
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}
