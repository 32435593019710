@import 'styles/variables/colors';

.container {
  flex: 1 1 calc(33.333% - 1rem);
  max-width: calc(33.333% - 1rem);
  margin: 0 0 0.8rem 0;
  box-sizing: border-box;
}

.productCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #262626;
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
}

.image {
  flex-shrink: 0;
  height: 150px;
  overflow: hidden;
  background: #111;
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.info {
  flex-grow: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.6rem;
  margin-bottom: 10px;
  color: $white;
  flex-grow: 1;
}

.price {
  font-size: 1.25rem;
  font-weight: 500;
  opacity: 0.6;
}

@media (max-width: 480px) {
  .cont {
    max-width: 100%;
    padding: 0 20px;
  }

  .image {
    height: 200px;
  }

  .title {
    font-size: 1.3rem;
  }

  .price {
    font-size: 1.1rem;
  }
}
