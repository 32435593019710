@import '../../styles/variables/colors';

.container {
  position: relative;
}

.label {
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 15px;
  font-size: 18px;
}

.button {
  width: 160px;
  padding: 12px 18px 12px 0;
  border-bottom: thin solid rgba($romance, 0.5);
  color: rgba($romance, 0.5);
}

.picker {
  position: absolute;
  left: 0;
  top: calc(100% + 16px);
  z-index: 9999;
}

/* stylelint-disable */
:export {
  blackColor: $black;
  lightColor: rgba($romance, 0.9);
  ligthTransparentColor: rgba($romance, 0.3);
}
