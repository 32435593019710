@import '../../styles/variables/colors';

.container {
  width: 100%;
  min-height: calc(100vh - 60px);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: rgba($romance, 0.85);
  margin-bottom: 34px;
}

.actions {
  margin-top: auto;
  margin-bottom: 100px;
}

.ordersLink {
  transform: translateY(30px);
}

.link {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: rgba($romance, 0.5);
}

.activeLink {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: rgba($romance, 0.85);
  margin-left: 45px;
}
