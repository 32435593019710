.hg-theme-default {
  max-width: 100vw;
}

.simple-keyboard .hg-button.hg-selectedButton {
  background: rgba(5, 25, 70, 0.53);
  color: white;
}

.hg-button.hg-functionBtn.hg-button-space {
  width: 350px;
}

.hg-button.hg-functionBtn.hg-button-enter {
  font-size: 24px;
  width: 110px;
}

.hg-button.hg-functionBtn.hg-button-bksp {
  font-size: 24px;
  width: 148px;
}

.hg-button.hg-button.hg-functionBtn.hg-button-shiftleft,
.hg-button.hg-button.hg-functionBtn.hg-button-shiftright {
  font-size: 24px;
}

.hg-theme-default .hg-button.hg-functionBtn.hg-button-empty {
  background: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
  border: none;
  cursor: initial;
  pointer-events: none;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
}

.hg-theme-default.hg-theme-therapy {
  background: black;
  border-radius: 0;
}

.hg-theme-default.hg-theme-therapy .hg-button {
  -webkit-box-shadow: none;
          box-shadow: none;
  background: transparent;
  border: thin solid #edeae3;
  color: #edeae3;
  border-radius: 15px;
  height: 60px;
  font-size: 26px;
}

.hg-theme-default.hg-theme-therapy .hg-button.hg-activeButton {
  background: #edeae3;
  color: black;
}

.hg-theme-default.hg-layout-numeric .hg-button {
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  height: 70px;
}

.hg-theme-default.hg-layout-numeric .hg-button.hg-functionBtn.hg-button-bksp,
.hg-theme-default.hg-layout-numeric .hg-button.hg-functionBtn.hg-button-enter {
  width: 33.3%;
}

.hg-theme-default.hg-layout-floating .hg-button.hg-functionBtn.hg-button-enter,
.hg-theme-default.hg-layout-floating .hg-button[data-skbtn^='0'] {
  width: 18%;
}

.hg-theme-default.hg-layout-floating .hg-button.hg-functionBtn.hg-button-bksp,
.hg-theme-default.hg-layout-floating .hg-button[data-skbtn^='.'] {
  width: 0;
}
