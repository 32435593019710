@import '../../styles/variables/colors.scss';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.outerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(33.33% - 10px);
  text-align: center;
}

.infoContainer {
  line-height: 28px;
  margin-bottom: -10px;
  padding-top: 5px;

  h1 {
    font-size: 16px;
    margin-bottom: -4px;
  }

  h2 {
    font-size: 18px;
  }
}

.info {
  display: flex;
  flex-direction: row;
  text-align: left;

  img {
    margin-right: -14px;
    width: 14px;
  }

  span {
    margin-left: 24px;
  }
}

.heading {
  margin: 30px 0 5px 0;
}

.approvalHeading {
  margin: 30px 0 5px 0;
  color: $red;
}

.pinInput {
  background-color: $romance;
  border-radius: 25px;
  color: $lightBlack;
  margin: 10px 0 10px 0;
  padding: 0;
  text-align: center;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.pinInput input {
  width: 0;
  height: 0;
  opacity: 0;
}

.pinDotContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100;
  font-size: 32px;
  margin: 0 80px;
}

.pinDot {
  width: 16px;
  height: 16px;
  background-color: $lightBlack;
  border-radius: 50%;
}

.pinInput input:focus + .pinDotContainer .pinDot:last-child {
  box-shadow: 0 0 4px $romance;
}

.keypad {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 4px;
}

.keypadButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: $lightBlack;
  color: $romance;
  border: 1px solid $romance;
  font-size: 27px;
  margin: 10px;
}

.buttonTitle {
  display: flex;
  justify-content: space-between;
  gap: 126px;

  span {
    height: 30px;
  }
}

.pinSubmitButton {
  background-color: $brand;
  color: $white;
  font-size: 16px;
  height: 43px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  margin-top: 15px;
}

.cancelButton {
  font-size: 16px;
  height: 43px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  margin-top: 15px;
}

.brandName {
  text-transform: uppercase;
}

.warehouseName {
  font-weight: 900;
  padding-bottom: 10px;
}

.linkButton {
  font-size: 14px;
  text-align: center;
  margin-top: 30px;

  span {
    text-decoration: underline;
    font-weight: bold;
  }
}
