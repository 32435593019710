@import '../../styles/variables/colors';

.container {
  padding: 12px 18px;
  text-transform: uppercase;
  background-color: $codGray;
  border-radius: 16px;
  font-size: 14px;
  color: rgba($romance, 0.5);
  transition: 0.2s;
}

.activeContainer {
  background-color: $tundora;
  color: rgba($romance, 0.85);
}
