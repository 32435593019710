@import '../../styles/variables/colors';

.container {
  position: relative;
}

.label {
  display: block;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 18px;
}

.inputContainer {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.inputWrapper {
  &:not(:last-child) {
    margin-right: 30px;
  }
}

.input {
  width: 100%;
  padding: 8px 8px 8px 0;
  border-bottom: thin solid transparentize($romance, 0.5);
  color: rgba($romance, 0.5);
  font-size: 18px;

  &::placeholder {
    opacity: 0.5;
  }
}
