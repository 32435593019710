@import '../../../styles/variables/colors.scss';

.block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.headerBlock {
  display: flex;
  flex-direction: column;
}

.headerBlockRight {
  font-size: 15px;
  color: rgba($color: $romance, $alpha: 0.5);
  text-align: right;
}

.customerName {
  font-size: 22px;
  font-weight: bold;
  color: $romance;
  margin-bottom: 8px;
}

.customerTime {
  font-size: 18px;
  color: $romance;
}
