@import '../../styles/variables/colors';

.container {
  position: relative;
  display: flex;
  height: 150px;
  width: 120px;
  padding: 14px 5px 14px 11px;
  //background: #262626;
  background: linear-gradient(0.25turn, transparent, #343434, transparent),
    linear-gradient(#262626, #262626),
    radial-gradient(38px circle at 19px 19px, #262626 50%, transparent 51%),
    linear-gradient(#262626, #262626);
  background-repeat: no-repeat;
  background-size: 315px 250px, 315px 180px, 100px 100px, 225px 30px;
  background-position: -315px 0, 0 0, 0 190px, 50px 195px;
  animation: loading 1.5s infinite;
  cursor: progress;
  border-radius: 10px;
}

.titleWrapper {
  width: 100%;
  align-self: flex-end;
}

.title {
  background-color: rgba($tundora, 0.7);
  width: 90%;
  height: 12px;
  border-radius: 20px;
}

.subtitle {
  background-color: rgba($tundora, 0.7);
  width: 70%;
  height: 12px;
  border-radius: 20px;
  margin-bottom: 10px;
}

@keyframes loading {
  to {
    background-position: 315px 0, 0 0, 0 190px, 50px 195px;
  }
}
