@import '../../styles/variables/colors.scss';

.root,
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
}

.heading {
  width: 100%;
  margin: 50px 0 10px 0;

  h1,
  p {
    text-align: center;
  }

  h1 {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 800;
  }

  p {
    font-size: 20px;
  }
}

.group {
  width: 50%;
  max-height: 400px;
  overflow-y: auto;

  label {
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: 800;
  }
}

.warehouseOption {
  background: $white;
  color: $black;
  padding: 20px;
  margin: 25px 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
}

.select {
  font-size: 1.2em;
}
