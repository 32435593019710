@import 'styles/variables/colors';

.cell {
  padding-top: 20px;
  font-size: 18px;
}

.container {
  .cell {
    border-bottom: 2px solid rgba(237, 234, 227, 0.103174);
    padding-bottom: 20px;
    margin-left: 30px;
  }
}

.disabledContainer {
  opacity: 0.4;
  user-select: none;
}

.lastContainer {
  .cell {
    border-bottom: 2px solid rgba(237, 234, 227, 0.103174);
    padding-bottom: 24px;
  }
}

.checkboxCell {
  width: 140px;
}

.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
}

.countCell {
  text-align: center;
  color: rgba($romance, 0.4);
}

.priceCell {
  text-align: right;
  padding-right: 30px;
}

.icon {
  position: absolute;
  left: 0;
  opacity: 0.5;
}
