@import '../../styles/variables/colors';

.foodCard {
  position: relative;
  height: 150px;
  width: 120px;
  padding: 10px 11px 16px 11px;
  background: #262626;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  letter-spacing: 1.27px;
}

.foodCardTitle {
  font-size: 18px;
}

.foodCardPrice {
  font-size: 16px;
  position: absolute;
  top: 20px;
  right: 18px;
  opacity: 0.4;
}

.title {
  font-size: 25px;
  line-height: 28px;
  margin-bottom: 8px;

  span {
    margin-right: 12px;
  }
}

.additions {
  margin-top: 40px;
}

.chips {
  margin-top: 40px;
}

.chipsWrapper {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 15px;
}

.subtitle {
  color: $white;
  font-size: 20px;
  margin-bottom: 20px;
}

.notes {
  margin-top: 40px;
}

.textarea {
  width: 430px;
  height: 200px;
  border-radius: 12px;
  background-color: $codGray;
  padding: 20px;
  font-size: 18px;
  line-height: 22px;
}

.button {
  padding: 12px 18px;
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  border: 1px solid $white;
  cursor: pointer;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1.27px;
}
