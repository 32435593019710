@import 'styles/variables/colors';

.info {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.infoTitle {
  color: rgba($romance, 0.5);
  text-transform: uppercase;
  font-size: 18px;
}

.infoContent {
  display: flex;
  color: $romance;
  margin-top: 8px;
  font-size: 18px;
}
